
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class ConfirmCosts extends Vue{
  get path() {
    return this.$route.path;
  }

}
